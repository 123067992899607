import { convertQs } from '../typebox';
import { encodeQs } from '../utils/apiCommon';
import { schemedDecode } from '@getmo/common/schemed';
import { R } from '@getmo/common/vendor/remeda';
import { type StaticDecode, type TObject, type TSchema, Type } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';
// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
import { type LocationQuery } from '#vue-router';

const queryUpdates: LocationQuery = {};

export default <PropSchemas extends Record<string, TSchema>>(
  params: PropSchemas,
  defaults?: StaticDecode<TObject<PropSchemas>>,
) => {
  const emptyValues = R.pipe(
    params as Record<string, TSchema>,
    R.keys(),
    R.mapToObj((k) => [k, undefined]),
  );
  const schema = Type.Object(params);

  const route = useRoute();
  // Convert strings to numbers, set defaults, decode
  const decode = (v: LocationQuery) => {
    const converted = convertQs(schema, { ...v });
    const cleaned = Value.Clean(schema, Value.Default(schema, converted));
    try {
      const decoded = schemedDecode(schema, cleaned);
      for (const [key, value] of R.entries(defaults ?? {}) as [keyof typeof defaults, never][]) {
        if ((decoded[key] as unknown) === undefined) {
          decoded[key] = value;
        }
      }
      return decoded;
    } catch (error) {
      log.error('Error decoding query', {
        errors: [...Value.Errors(schema, cleaned)],
      });
      throw error;
    }
  };

  const q = reactive(decode(route.query));

  const { pause: pauseStateUpdates, resume: resumeStateUpdates } = watchPausable(
    () => route.query,
    (v) => {
      ignoreStateUpdates(() => {
        Object.assign(q, {
          ...emptyValues,
          ...decode(v),
        });
      });
    },
  );

  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { ignoreUpdates: ignoreStateUpdates } = watchIgnorable(q, async (v: any) => {
    pauseStateUpdates();

    Object.assign(queryUpdates, { ...emptyValues, ...encodeQs(schema, v) });

    await nextTick();

    if (!R.isEmpty(queryUpdates)) {
      const query = { ...route.query, ...queryUpdates };

      for (const key of R.keys(queryUpdates)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete queryUpdates[key];
      }

      await router.replace({ query });
    }

    resumeStateUpdates();
  });

  return q;
};
